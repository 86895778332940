// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("xzbypkrn");
  if (state.succeeded) {
      return <p>Thanks for your message! We'll get back to you shortly.</p>;
  } 
  // console.log(state)
  
  return (
    <form onSubmit={handleSubmit}>
      <ValidationError prefix="Errors" errors={state.errors} />

      <label htmlFor="name">Name</label>
      <input id="name" type="text" name="name" required />
      <ValidationError prefix="Name" field="name" errors={state.errors} />

      <label htmlFor="email">Email</label>
      <input id="email" type="email" name="_replyto" required />
      <ValidationError prefix="Email" field="_replyto" errors={state.errors} />

      <label htmlFor="telephone">Phone</label>
      <input type="tel" name="phone" id="phone" required />
      <ValidationError prefix="Phone" field="phone" errors={state.errors} />

      <label for="address">Address</label>
      <input type="text" name="address" id="address" required />
      <ValidationError prefix="Address" field="address" errors={state.errors} />

      <fieldset class="d-flex">
        <legend>Postal Code, City</legend>
        <input
          type="text"
          name="postal-code"
          id="postal-code"
          className="short"
          required
        />
        <ValidationError
          prefix="Postal Code"
          field="postal-code"
          errors={state.errors}
        />
        <input type="text" name="city" id="city" required />
        <ValidationError prefix="City" field="city" errors={state.errors} />
      </fieldset>

      <label htmlFor="message">Message</label>
      <textarea id="message" name="message" rows="6" required />
      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  )
}

export default ContactForm
